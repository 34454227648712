<template>
  <!-- Modal Suggestion Copy Business -->

  <app-modal
    :show="showModalCopySuggestion"
    @close="showModalCopySuggestion = false"
    :textButtonCancel="$t('business.copy.suggestion_button_no')"
  >
    <template v-slot:title>{{ $t("business.copy.suggestion_title") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("business.copy.suggestion_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button-outline
        @click="
          (showModalCopySuggestion = false), (showModalCopyBusiness = true)
        "
        class="
          w-full
          outline-none
          bg-approve
          text-green-50
          hover:bg-success
          sm:ml-3 sm:w-auto
        "
      >
        {{ $t("business.copy.suggestion_button_yes") }}
      </app-button-outline>
    </template>
  </app-modal>

  <!-- Modal Copy Business-->

  <app-modal-copy-business
    :loading="copyBusinessLoading"
    :show="showModalCopyBusiness"
    @show="showModalCopyBusiness = $event"
    @copy-business="copyBusiness"
  />

  <!-- Business-->
  <div class="flex flex-col bg-white h-screen w-full">
    <div class="flex-none h-16">
      <div class="border-2 flex flex-row h-full">
        <div class="flex-none grid place-items-center w-20">
          <app-link :to="{ name: 'dashboard' }" class="text-black"
            ><app-icon name="XIcon" class="w-5 cursor-pointer"></app-icon
          ></app-link>
        </div>
        <div class="flex-1 flex justify-between border-l-2 items-center px-10">
          <app-button-business-status
            :class="{
              'hidden lg:flex':
                business.business_status_id == BUSINESS_STATUS.APPROVED,
            }"
          />
          <p
            v-if="business.business_status_id == BUSINESS_STATUS.APPROVED"
            class="text-sm"
          >
            {{ $t("business.main.business_approved") }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex-1 h-full md:grid grid-cols-4 block">
      <div class="border-r border-gray-100 px-5 py-10 space-y-2">
        <app-button-menu
          class="w-full space-x-3 flex items-center font-medium"
          v-for="(menu, index) in menu_items"
          v-bind:key="menu.title"
          :fullWidth="true"
          @onMenuClick="onMenuClick(menu)"
          :active="isCurrentPath(menu.path)"
          :disabled="isDisabled(menu)"
          :show="isShow(menu)"
        >
          <span
            class="
              w-8
              h-8
              grid
              border-2
              flex-none
              rounded-full
              place-items-center
            "
            :class="[
              isDone(menu) ? 'border-success bg-success' : 'bg-white',
              isCurrentPath(menu.path)
                ? 'border-primary bg-primary text-white'
                : 'border-gray-400 text-gray-400',
            ]"
          >
            <app-icon
              v-if="isDone(menu)"
              name="CheckIcon"
              class="h-6 w-6 text-white"
            />
            <p v-else>{{ index + 1 }}</p>
          </span>
          <p
            :class="[
              'text-left',
              isDisabled(menu) ? 'text-gray-400' : 'text-black ',
            ]"
          >
            {{ menu.title }}
          </p>
        </app-button-menu>
      </div>
      <div class="col-span-3 md:px-6 py-10 px-10 space-y-2">
        <div class="mb-5 md:pl-10">
          <div
            v-if="
              business.business_status_id == BUSINESS_STATUS.REJECTED ||
              business.business_status_id == BUSINESS_STATUS.AMENDED
            "
          >
            <app-container-comments />
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Role from "@/utils/const/role";
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopySuggestion: false,
      showModalCopyBusiness: false,
      copyBusinessLoading: false,
      menu_items: [
        { id: 1, title: "Business structure", path: "/business/structure" },
        { id: 2, title: "Business details", path: "/business/detail" },
        {
          id: 3,
          title: "Business director/representative",
          path: "/business/representative",
        },
        { id: 4, title: "Business owners", path: "/business/owners" },
        { id: 5, title: "Support details", path: "/business/support-details" },
        { id: 6, title: "Bank details", path: "/business/bank-details" },
        {
          id: 7,
          title: "Supporting documents",
          path: "/business/support-documents",
        },
        // {
        //   id: 8,
        //   title: "Packages",
        //   path: "/business/business-packages",
        // },
        { id: 9, title: "Summary", path: "/business/summary" },
        { id: 10, title: "Company Logo", path: "/business/company-logo" },
      ],
    };
  },
  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    businessLogo() {
      return this.$store.getters["businessStore/logo"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    selectionRoles() {
      return this.$lodash.sortBy(
        this.$store.getters["authStore/selectionRoles"] ?? [],
        (selectionRole) => selectionRole.business
      );
    },
    totalBusiness() {
      return (
        this.selectionRoles?.filter(function (modelHasRole) {
          return (
            modelHasRole.business != null &&
            modelHasRole.role_id == Role.BUSINESS_OWNER
          );
        })?.length ?? 0
      );
    },
    user() {
      return this.$store.getters["authStore/user"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    support_documents() {
      return this.$store.getters["businessStore/support_documents"];
    },
    validate_support_documents() {
      return this.$store.getters["businessStore/validate_support_documents"];
    },
  },
  watch: {
    all_business(businesses) {
      this.showModalCopySuggestion =
        !this.selectedRole &&
        businesses.length > 0 &&
        this.totalBusiness < this.user.max_business;
    },
  },
  beforeCreate() {
    this.$store.dispatch("businessStore/getBusinessFromSelectedRoleBusinessId");
    this.$store.dispatch("businessStore/getBusinessSupportDocuments");
    this.$store.dispatch("businessStore/validateBusinessSupportDocuments");
    this.$store.dispatch("businessStore/typeBusinessSupportDocuments");
  },
  methods: {
    async copyBusiness(business) {
      this.copyBusinessLoading = true;

      if (business == null) return;

      const businessResult = await this.$store.dispatch(
        "businessStore/copyBusiness",
        business.id
      );

      if (businessResult == null || this.$lodash.isEmpty(businessResult)) {
        this.copyBusinessLoading = false;
        return;
      }

      const refreshUserResult = await this.$store.dispatch(
        "authStore/refreshUser"
      );

      if (refreshUserResult == null) {
        this.copyBusinessLoading = false;
        return;
      }

      const selectedRoleResult = await this.$store.dispatch(
        "authStore/setSelectedRoleByBusinessId",
        businessResult.id
      );

      if (selectedRoleResult == null) {
        this.copyBusinessLoading = false;
        return;
      }

      this.$store.dispatch("businessStore/getBusinessSupportDocuments");
      this.$store.dispatch("businessStore/validateBusinessSupportDocuments");
      this.$store.dispatch("businessStore/typeBusinessSupportDocuments");

      this.showModalBusiness = false;

      setTimeout(() => {
        this.copyBusinessLoading = false;
      }, 1000);
    },

    onMenuClick(menu) {
      this.$router.push({ path: menu.path });
    },

    isCurrentPath(path) {
      return this.$route.path == path;
    },

    isBusinessCreated() {
      return this.business != null ? this.business.id != null : false;
    },

    isBusinessDetailsCreated() {
      return this.business.detail != null
        ? this.business.detail.id != null
        : false;
    },

    isBusinessRepresentativeCreated() {
      return this.business.representative != null
        ? this.business.representative.id != null
        : false;
    },

    isBusinessOwnersCreated() {
      return this.business.owners != null
        ? this.business.owners.length > 0
        : false;
    },

    isBusinessSupportDetailsCreated() {
      return this.business.support_detail != null
        ? this.business.support_detail.id != null
        : false;
    },

    isBusinessBanksCreated() {
      return this.business.bank != null ? this.business.bank.id != null : false;
    },

    isBusinessPackageCreated() {
      return this.business.package != null
        ? this.business.package.id != null
        : false;
    },

    isBusinessSubmitted() {
      return (
        this.business.business_status_id >= BUSINESS_STATUS.SUBMITTED &&
        this.business.business_status_id !== BUSINESS_STATUS.AMENDED
      );
    },

    isBusinessSupportDocumentsCreated() {
      return !this.$lodash.isEmpty(this.support_documents);
    },

    isBusinessSupportDocumentsValidate() {
      return this.validate_support_documents;
    },

    isBusinessApproved() {
      return this.business.business_status_id >= BUSINESS_STATUS.APPROVED;
    },

    isBusinessCompanyLogo() {
      return this.businessLogo != null;
    },

    isDone(menu) {
      switch (menu.id) {
        case 1:
          return this.isBusinessCreated();
        case 2:
          return this.isBusinessDetailsCreated();
        case 3:
          return this.isBusinessRepresentativeCreated();
        case 4:
          return this.isBusinessOwnersCreated();
        case 5:
          return this.isBusinessSupportDetailsCreated();
        case 6:
          return this.isBusinessBanksCreated();
        case 7:
          return this.isBusinessSupportDocumentsValidate();
        // case 8:
        //   return this.isBusinessPackageCreated();
        case 9:
          return this.isBusinessSubmitted();
        case 10:
          return this.isBusinessCompanyLogo();
        default:
          return false;
      }
    },

    isDisabled(menu) {
      switch (menu.id) {
        case 1:
          return false;
        // case 9:
        //   return !this.isBusinessApproved();
        default:
          return !this.isBusinessCreated();
      }
    },

    isShow(menu) {
      switch (menu.id) {
        case 1:
          return true;
        // case 9:
        //   return this.isBusinessApproved();
        default:
          return true;
      }
    },
  },
};
</script>
